export const FirebaseConfig = {
	"projectId": "cashryde2",
	"appId": "1:665580647033:web:d4cb774ede6ab202e51aa8",
	"databaseURL": "https://cashryde2-default-rtdb.firebaseio.com",
	"storageBucket": "cashryde2.appspot.com",
	"locationId": "us-central",
	"apiKey": "AIzaSyAoDjb3pbB7NuARDnYosDBfvotI-ACnOG0",
	"authDomain": "cashryde2.firebaseapp.com",
	"messagingSenderId": "665580647033",
	"measurementId": "G-NP3XW6XGHZ"
};